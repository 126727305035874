import React, { useState } from "react";
import Tile from "./Tile";
import { TILE_COUNT, GRID_SIZE, BOARD_SIZE } from "./constants"
import { canSwap, shuffle, swap, isSolved } from "./helpers"

function Board({ imgUrl }) {
  const [tiles, setTiles] = useState([...Array(TILE_COUNT).keys()]);
  const [isStarted, setIsStarted] = useState(false);
  console.log('is started:', isStarted)

  const shuffleTiles = () => {
     let randomArray = [];

     for (let i = TILE_COUNT - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [tiles[i], tiles[j]] = [tiles[j], tiles[i]];
    }

    const shuffledTiles = shuffle(tiles)
    console.log("hlal", tiles)
    setTiles(shuffledTiles);
  }

  const swapTiles = (tileIndex) => {
    if (canSwap(tileIndex, tiles.indexOf(tiles.length - 1))) {
      const swappedTiles = swap(tiles, tileIndex, tiles.indexOf(tiles.length - 1))
      setTiles(swappedTiles)
    }
  }

  const handleTileClick = (index) => {
    console.log("hola", index)
    swapTiles(index)
  }

  const handleShuffleClick = () => {
    shuffleTiles()
  }

  const handleStartClick = () => {
    shuffleTiles()
    setIsStarted(true)
  }

  const pieceWidth = Math.round(BOARD_SIZE / GRID_SIZE);
  const pieceHeight = Math.round(BOARD_SIZE / GRID_SIZE);
  const style = {
    width: "48.5%",
    height: "196%",
  };
  const hasWon = isSolved(tiles)

  return (
    <>
      <ul className="board">
        {tiles.map((tile, index) => (
          <Tile
            key={tile}
            index={index}
            imgUrl={`/number${tile}.png`}
            tile={tile}
            width={pieceWidth}
            height={pieceHeight}
            handleTileClick={handleTileClick}
          />
        ))}
      </ul>

      {hasWon && isStarted && <div>Puzzle solved 🧠 🎉</div>}
      <div style={{ width: "100%", display: "flex", justifyContent: "center", position: "absolute", bottom: "5%" }}>
      {!isStarted ?
        (<button className="butotom" onClick={() => handleStartClick()}>
          <img src="/plsy.png" />
        </button>) :
        (<button className="butotom" onClick={() => handleShuffleClick()} style={{ width: "100%" }}>
        <img src="/intentar.png" style={{ width: "80%" }} />
      </button>)}
      </div>

    </>
    
  );
}

export default Board;
