import React from "react";
import { Motion, spring } from "react-motion";
import { getMatrixPosition, getVisualPosition } from "./helpers";
import { TILE_COUNT, GRID_SIZE, BOARD_SIZE } from "./constants"

function Tile(props) {
  const { tile, index, width, height, handleTileClick, imgUrl } = props;
  console.log("img in tile", imgUrl)
  const { row, col } = getMatrixPosition(index);
  const visualPos = getVisualPosition(row, col, width, height);
  const tileStyle = {
    width: `calc(100% / 4)`,
    height: `calc(100% / ${GRID_SIZE -1})`,
    translateX: visualPos.x,
    translateY: visualPos.y,
    backgroundImage: `url(${imgUrl})`,
    color:" #fff",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `center`,

  };
  const motionStyle = {
    translateX: spring(visualPos.x),
    translateY: spring(visualPos.y)
  }
  
  const primerosNumeros = {
    4 : "0",
    5 : "1",
    6 : "2",
    7 : "3"
  }


  const segundosNumeros = {
    8 : "0",
    9 : "1",
    10 : "2",
    11 : "3"
  }

  const indexT = index < 4 ? `calc(100% * ${index}), calc(100% * 0)`
    : index < 8 ? `calc(100% * ${primerosNumeros[index]}), calc(100% * 1)`
    :  `calc(100% * ${segundosNumeros[index]}), calc(100% * 2)`

  return (
    <Motion style={motionStyle}>
      {({ translateX, translateY }) => (
        <li
          style={{
            ...tileStyle,
            transform: `translate3d(${indexT},  0)`,
            // Is last tile?
            opacity: tile === TILE_COUNT - 1 ? 0 : 1,
          }}
          className="tile"
          onClick={() => handleTileClick(index)}
        >
          {/* {!imgUrl && `${tile + 1}`} */}
        </li>
      )}
    </Motion>
  );
}

export default Tile;
